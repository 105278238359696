import { useState } from "react";

import { ProductType } from "../firebase/index";

type SlideProps = {
  productData: ProductType | null;
  productId: string;
};

export const Slide = ({ productData, productId }: SlideProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const assets = Object.values(productData?.productSlides || {}).map(
    (slide) => {
      const filename = slide.split("/").pop();
      return `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${productId}/slide/${filename}`;
    }
  );

  if (
    !productData ||
    !productData.productSlides ||
    Object.keys(productData.productSlides).length === 0
  ) {
    assets.push(
      `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${productId}/slide/parapara.mp4`,
      `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${productId}/slide/postcard.webp`,
      `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${productId}/slide/digital.mp4`
    );
  }

  const handleNavigation = (direction: "left" | "right") => {
    setCurrentIndex((prev) => {
      if (direction === "left") {
        return prev === 0 ? assets.length - 1 : prev - 1;
      } else {
        return prev === assets.length - 1 ? 0 : prev + 1;
      }
    });
  };

  const isVideo = (asset: string) => {
    const url = new URL(asset);
    const pathname = url.pathname;
    return pathname.endsWith(".mp4");
  };

  return (
    <>
      <div className="relative mx-auto w-full overflow-hidden">
        {assets.map((asset, index) => (
          <div
            key={asset + index}
            className={`${
              currentIndex === index ? "block" : "hidden"
            } size-full`}
          >
            {isVideo(asset) ? (
              <video
                src={asset}
                autoPlay
                loop
                playsInline
                controls={false}
                muted={true}
                className="size-full"
              />
            ) : (
              <img src={asset} alt={`Slide ${index}`} className="size-full" />
            )}
          </div>
        ))}
        <div className="absolute bottom-3 left-1/2 flex -translate-x-1/2">
          {assets.map((_, index) => (
            <div
              key={index}
              className={`${
                currentIndex === index ? "bg-gray-600" : "bg-gray-400"
              } mx-1 size-3 cursor-pointer rounded-full transition-colors`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
        <div
          className="absolute left-2 top-1/2 size-12 -translate-y-1/2 cursor-pointer select-none rounded-full bg-black/50 text-center text-2xl leading-[3rem] text-white"
          onClick={() => handleNavigation("left")}
        >
          {"<"}
        </div>
        <div
          className="absolute right-2 top-1/2 size-[48px] -translate-y-1/2 cursor-pointer select-none rounded-full bg-black/50 text-center text-2xl leading-[3rem] text-white"
          onClick={() => handleNavigation("right")}
        >
          {">"}
        </div>
      </div>
    </>
  );
};
